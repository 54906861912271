import { Appearance } from "react-native";

export const CUSTOM_COLORS = {
  // main: '#ead017',
  // gold: '#ead017',
  // monoGold: '#d1c03e',
  // green: '#4cea17',
  // compRed: '#ea174c',
  // anaGreenOne: '#17ea80',
  // anaGreenTwo: '#b5ea17',

  safetyYellow: '#EAD017',
  lightSafetyYellow: '#F8F0B9',
  superLightSafetuYellow: '#FCF9E3',
  steelBlue: '#427AA1 ',
  aliceBlue: '#EBF2FA',
  maximumGreen: '#679436',
  acidGreen: '#A5BE00',
  whiteSmoke: '#F5F5F5',
  lightWhiteSmoke: '##fbfbfb',
}

export const getTextColourStyle = () => {
  const cs = Appearance.getColorScheme();
  if (cs == 'dark') {
    return { color: 'black' }
  }
  else {
    return { color: 'white' }
  }
}